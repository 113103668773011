@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins";
  scroll-behavior: smooth;
}

@font-face {
  font-family: "YourFontName";
  src: url("./fonts/Century\ Gothic.ttf") format("truetype"); /* Use 'opentype' if using OTF */
  font-weight: normal;
  font-style: normal;
}

.swiper-pagination-bullet {
  width: 25px !important;
  border-radius: 20% !important;
  background: #000 !important;
  opacity: 0.2 !important;
  height: 2px !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
  background: #000 !important;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 16px !important;
}

.swiper-button-prev {
  display: block;
  color: #000 !important;
  background-color: #fff;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  padding: 5px;
}

.swiper-button-prev::after {
  font-size: 15px !important;
  font-weight: 900;
}

.swiper-button-next {
  display: block;
  color: #000 !important;
  background-color: #fff;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  padding: 5px;
}

.swiper-button-next::after {
  font-size: 15px !important;
  font-weight: 900;
}

@media only screen and (max-width: 600px) {
  .swiper-pagination-bullet {
    width: 15px !important;
    border-radius: 20% !important;
    background: #000 !important;
    opacity: 0.2 !important;
    height: 2px !important;
  }

  .swiper-pagination-bullet-active {
    opacity: 1 !important;
    background: #000 !important;
  }
}
